import { Typography, useTheme } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { TknErrorList } from '../TknErrorList';
import { TknSnackbarWithIcon } from './TknSnackbarWithIcon';
import { useTranslation } from 'next-i18next';

interface TknValidationErrorSnackbarProps {
  onClose: () => void;
  errorListOptions: string[];
}

export const TknValidationErrorSnackbar = ({
  onClose,
  errorListOptions,
}: TknValidationErrorSnackbarProps) => {
  const theme = useTheme();
  const { t } = useTranslation('error');
  return (
    <TknSnackbarWithIcon
      icon={<WarningAmberIcon />}
      title={t('register.validationTitle')}
      iconColor={theme.palette.global.error as string}
      variant={'black'}
      id="validation-error"
      onClose={onClose}
    >
      <>
        <Typography variant="descriptionRegular">
          {t('register.validationText')}
        </Typography>
        <TknErrorList listOptions={errorListOptions} />
      </>
    </TknSnackbarWithIcon>
  );
};
