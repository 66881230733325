import { ReactNode } from 'react';
import { SxProps, InputAdornment, IconButton } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { TknSvgIcon } from '../TknSvgIcon';

interface TknErrorAdornmentProps {
  error: boolean;
  prefix?: ReactNode;
  sx?: SxProps;
}

export const TknErrorAdornment = ({
  error,
  prefix,
  sx,
}: TknErrorAdornmentProps) => {
  return (
    <InputAdornment position="end">
      {prefix}
      <IconButton aria-label="Error icon" sx={sx}>
        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <TknSvgIcon src="/icons/red-alert.svg" alt="Input error" />
            </motion.div>
          )}
        </AnimatePresence>
      </IconButton>
    </InputAdornment>
  );
};
