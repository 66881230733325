import {
  FormControl,
  Input,
  InputLabel,
  SxProps,
  useTheme,
} from '@mui/material';
import { Control, Controller, ControllerFieldState } from 'react-hook-form';
import { isFunction } from '../../utils/tkn-util';
import { RuleValidationProps } from './common/RulesValidationProps';
import InputMask from 'react-input-mask';
import { FieldError } from 'react-hook-form';
import { ChangeEventHandler, RefCallback, RefObject, useCallback } from 'react';
import { DEFAULT_ERROR_COLOR } from '../../styles/themes/tkn-mvp.theme';
import { TknErrorAdornment } from './TknErrorAdornment';

export interface TknInputProps {
  id: string;
  label?: string | ((formFieldState: ControllerFieldState) => string);
  variant: 'outlined' | 'filled' | 'standard';
  color?: string;
  errorColor?: string;
  control: Control<any>;
  rules?: RuleValidationProps;
  mask?: string;
  inputComponent?: any;
  inputProps?: any;
  type?: string;
  isDisabled?: boolean;
}
interface TknInputStyle {
  control: (id: string, color: string, errorColor: string) => SxProps;
}

const style: TknInputStyle = {
  control: (id: string, color: string, errorColor: string) => {
    const input = document.getElementById(id);

    return {
      width: '100%',
      '& input': {
        color,
        fontSize: '14px',
      },
      '& input:-webkit-autofill': {
        caretColor: color,
        WebkitTextFillColor: color,
        WebkitBackgroundClip: 'text',
      },
      '& label': {
        color,
        fontSize: '14px',
        opacity: 0.4,
      },
      '& label.Mui-error': {
        color,
      },
      '& .MuiFormLabel-asterisk.Mui-error': {
        color: errorColor,
      },
      '& label.Mui-focused': {
        color,
        opacity: 0.4,
      },
      '& .MuiInput-underline': {
        borderBottomColor: color,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: color,
        opacity: 0.4,
      },
      '& .MuiInput-root:hover:not(.Mui-disabled):before': {
        borderBottomColor: color,
        opacity: 0.4,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: color,
        opacity: 0.4,
      },
      '& .Mui-error.MuiInput-underline': {
        borderBottomColor: errorColor,
      },
      '& .Mui-error.MuiInput-underline:before': {
        borderBottomColor: errorColor,
      },
      '& .Mui-error.MuiInput-root:hover:not(.Mui-disabled):before': {
        borderBottomColor: errorColor,
      },
      '& .Mui-error.MuiInput-underline:after': {
        borderBottomColor: errorColor,
      },
      '& button': {
        color: errorColor,
      },
      '& .MuiInputAdornment-root': {
        marginBottom: '9px',
      },
      '& input.Mui-disabled': {
        textFillColor: color,
        opacity: 0.4,
      },
      '& .MuiInput-root.Mui-disabled:before': {
        borderBottomStyle: 'solid',
        opacity: 0.25,
      },
      '& .MuiInputLabel-formControl[data-shrink=false]': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: input?.offsetWidth,
      },
    };
  },
};

export const TknInput = (props: TknInputProps) => {
  const theme = useTheme();

  const color = props.color ?? (theme.palette.global.white as string);
  const errorColor = props.errorColor ?? DEFAULT_ERROR_COLOR;

  const input = useCallback(
    (
      ref: RefCallback<HTMLInputElement> | RefObject<HTMLInputElement> | null,
      value: string | null,
      onChange: ChangeEventHandler | null,
      fieldState: { error?: FieldError }
    ) => (
      <Input
        id={props.id}
        name={props.id}
        ref={ref}
        type={props.type ?? 'text'}
        value={value}
        onChange={onChange ?? undefined}
        inputComponent={props.inputComponent}
        disabled={props.isDisabled}
        inputProps={props.inputProps}
        endAdornment={<TknErrorAdornment error={!!fieldState.error} />}
      />
    ),
    [props]
  );

  return (
    <Controller
      name={props.id}
      control={props.control}
      rules={props.rules}
      render={({ field: { onChange, value, ref }, fieldState }) => {
        return (
          <FormControl
            sx={style.control(props.id, color, errorColor)}
            variant={props.variant}
            error={!!fieldState.error}
          >
            {props.label && (
              <InputLabel htmlFor={props.id} required={props.rules?.required}>
                {isFunction(props.label)
                  ? props.label(fieldState)
                  : props.label}
              </InputLabel>
            )}
            {props.mask ? (
              <InputMask
                mask={props.mask}
                value={value}
                disabled={props.isDisabled}
                onChange={onChange}
              >
                {() => input(ref, null, null, fieldState)}
              </InputMask>
            ) : (
              input(ref, value, onChange, fieldState)
            )}
          </FormControl>
        );
      }}
    ></Controller>
  );
};
