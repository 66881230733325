import { alpha, styled, Switch } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { RuleValidationProps } from './common/RulesValidationProps';

interface TknSwitchProps {
  id: string;
  control: Control<any>;
  disabled?: boolean;
  rules?: RuleValidationProps;
}

const CustomSwitch = styled(Switch)(({ theme }) => {
  return {
    width: 48,
    height: 24,
    padding: 0,
    display: 'flex',
    borderRadius: 50,
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked:not(.Mui-disabled)': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.global.yellow,
          opacity: 1,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.palette.global.blue,
        },
      },
      '&.Mui-disabled': {
        opacity: 0.3,
        '& + .MuiSwitch-track': {
          opacity: 1,
          background: 'transparent',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: 18,
      height: 18,
      borderRadius: 50,
      backgroundColor: alpha(theme.palette.global.white as string, 0.6),
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: 'transparent',
      border: `1px solid ${alpha(theme.palette.global.white as string, 0.3)}`,
      borderRadius: '1000px',
      boxSizing: 'border-box',
    },
  };
});

export const TknSwitch = (props: TknSwitchProps) => {
  return (
    <Controller
      name={props.id}
      control={props.control}
      rules={props.rules}
      render={({ field: { onChange, value } }) => {
        return (
          <CustomSwitch
            id={props.id}
            disableRipple
            checked={value}
            disabled={props.disabled}
            onChange={onChange}
          />
        );
      }}
    />
  );
};
