import { TknInput, TknInputProps } from './TknInput';
import { PhoneNumberUtil } from 'google-libphonenumber';

type TknPhoneNumberProps = Pick<
  TknInputProps,
  'id' | 'label' | 'control' | 'rules' | 'variant' | 'isDisabled'
>;

interface TknPhoneNumberFullProps extends TknPhoneNumberProps {
  international?: boolean;
}

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export const TknPhoneNumberInput: React.FC<TknPhoneNumberFullProps> = (
  props
) => {
  return (
    <TknInput
      id={props.id}
      label={props.label}
      control={props.control}
      isDisabled={props.isDisabled}
      mask={props.international ? '' : '+1 (999) 999-9999'}
      rules={{
        ...props.rules,
        validate: (number: string) => {
          if (number == '') return true;
          try {
            const phoneNumber = phoneNumberUtil.parse(number);
            if (props.international) {
              return phoneNumberUtil.isValidNumber(phoneNumber);
            } else {
              return phoneNumberUtil.isValidNumberForRegion(phoneNumber, 'US');
            }
          } catch (e) {
            console.error(e);
            return false;
          }
        },
        // pattern: /^\+\d \(\d{3}\) \d{3}\-\d{4}$/,
        // pattern: /^\+(?:[0-9]●?){6,14}[0-9]$/, // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
        pattern: props.international ? /^\+(?:[0-9]●?){6,14}[0-9]$/ : /^\+\d \(\d{3}\) \d{3}\-\d{4}$/,
      }}
      variant={props.variant}
      inputProps={{
        inputMode: 'numeric',
      }}
    />
  );
};
