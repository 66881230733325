import { Grid, SxProps } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { useWindowSize } from 'react-use';

interface TknFixedButtonWrapperProps {
  children?: ReactNode;
  minimumHeight?: number;
}

interface TknFixedButtonWrapperStyles {
  wrapper: (isLandscape: boolean) => SxProps;
}

const styles: TknFixedButtonWrapperStyles = {
  wrapper: (isLandscape: boolean) =>
    isLandscape
      ? {
          mt: 4,
          pb: 3,
          px: 3,
        }
      : {
          width: '100%',
          position: 'fixed',
          left: 0,
          bottom: 0,
          padding: '24px 40px',
          justifyContent: 'center',
          alignItems: 'center',
        },
};

export const TknFixedButtonWrapper = ({
  children,
  minimumHeight = 0,
}: TknFixedButtonWrapperProps) => {
  const { width, height } = useWindowSize();
  const isLandscape = useMemo(
    () => width > height || height < minimumHeight,
    [width, height, minimumHeight]
  );

  return (
    <Grid item sx={styles.wrapper(isLandscape)}>
      {children}
    </Grid>
  );
};
