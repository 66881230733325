import { TknInput, TknInputProps } from './TknInput';

interface TknEmailInputProps extends TknInputProps {}

export const TknEmailInput = (props: TknEmailInputProps) => {
  return (
    <TknInput
      id={props.id}
      label={props.label}
      variant={props.variant}
      control={props.control}
      isDisabled={props.isDisabled}
      rules={{
        ...props.rules,
        pattern: /^\S+@\S+$/, //need to find a better pattern for this. 
      }}
    />
  );
};
