import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { TknSnackbarWithIcon } from './TknSnackbarWithIcon';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface TknServerErrorSnackBarProps {
  onClose: () => void;
}

export const TknServerErrorSnackBar = ({
  onClose,
}: TknServerErrorSnackBarProps) => {
  const { t } = useTranslation('error');
  const theme = useTheme();

  return (
    <TknSnackbarWithIcon
      icon={<WarningAmberIcon />}
      title={t('serverErrorTitle')}
      iconColor={theme.palette.global.error as string}
      variant={'black'}
      id="server-error"
      onClose={onClose}
    >
      <>
        <Typography component="p" variant="descriptionRegular" mb={2}>
          {t('serverErrorText', { context: 'FIRST' })}
        </Typography>
        <Typography component="p" variant="descriptionRegular">
          {t('serverErrorText', { context: 'SECOND' })}
          <a
            href="mailto:support@tknevent.com"
            style={{ textDecoration: 'none' }}
          >
            support@tknevent.com
          </a>
        </Typography>
      </>
    </TknSnackbarWithIcon>
  );
};
