import { Typography } from '@mui/material';
import React, { CSSProperties } from 'react';

interface TknErrorListProps {
  listOptions: string[];
}

interface TknErrorListStyles {
  listItem: CSSProperties;
  list: CSSProperties;
}

const styles: TknErrorListStyles = {
  list: {
    paddingLeft: '20px',
  },
  listItem: {
    margin: '8px 0',
  },
};

export const TknErrorList = ({ listOptions }: TknErrorListProps) => {
  return (
    <ul style={styles.list}>
      {listOptions.map((item) => {
        return (
          <li key={item} style={styles.listItem}>
            <Typography component="p" variant="descriptionRegular">
              {item}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
};
