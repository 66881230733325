import { useEffect, useState } from 'react';
import { Control, useFormState, useWatch } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

export const PHONE_NUMBER_PLACEHOLDER = '+1 (___) ___-____';

interface UseClearEmptyInputErrorOptions {
  control: Control<any, any>;
  name: string;
  clearErrors: Function;
  placeholder?: string;
}

export const useClearEmptyInputError = ({
  control,
  name,
  clearErrors,
  placeholder,
}: UseClearEmptyInputErrorOptions) => {
  const value = useWatch({ control, name });
  const { dirtyFields } = useFormState({ control });
  const [hasBeenDirty, setHasBeenDirty] = useState<boolean>(false);

  useEffect(() => {
    if (isEmpty(value) || value === placeholder) {
      if (!hasBeenDirty) {
        setTimeout(() => clearErrors(name), 0);
      }
    }
    if (dirtyFields[name]) {
      setHasBeenDirty(true);
    }
  }, [value, clearErrors, placeholder, name, dirtyFields, hasBeenDirty]);
};
