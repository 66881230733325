import { Box, LinearProgress, Typography } from '@mui/material';
interface TknStepProgressBarProps {
  textColor?: string;
  barColor?: string;
  fillColor?: string;
  currentStep: number;
  totalSteps: number;
}

function getProgressValue(currentValue: number, totalValue: number) {
  // MUI max value is 100, so we need to scale the value
  let progressValue = currentValue;
  if (progressValue + 1 >= totalValue) {
    progressValue = totalValue;
  }

  return (currentValue / totalValue) * 100;
}

export function TknStepProgressBar(props: TknStepProgressBarProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ minWidth: 40, width: '100%', height: 2, mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={getProgressValue(props.currentStep, props.totalSteps)}
          sx={{
            color: props.textColor ?? 'global.white',
            bgcolor: props.barColor ?? 'global.yellow',
            borderRadius: '10px',
            '& .MuiLinearProgress-bar': {
              bgcolor: props.fillColor ?? 'global.white',
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 55 }}>
        <Typography variant="minRegular">
          Step {props.currentStep} of {props.totalSteps}
        </Typography>
      </Box>
    </Box>
  );
}
