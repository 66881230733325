import { useState } from 'react';
import { ErrorType } from '../types/enums/ErrorType';

export const useError = () => {
  const [error, setError] = useState<ErrorType | null>(null);
  return {
    error,
    setError,
  };
};
