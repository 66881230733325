import { Grid, Typography, alpha, useTheme } from '@mui/material';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { AppHeader } from '../components/AppHeader';
import { TknPageContainer } from '../components/TknPageContainer';
import { calculateiOSInset } from '../utils/tkn-util';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { TknPhoneNumberInput } from '../components/form/TknPhoneNumberInput';
import { useForm } from 'react-hook-form';
import { useMemo, useCallback, useEffect } from 'react';
import { TknSwitch } from '../components/form/TknSwitch';
import { TknButton } from '../components/TknButton';
import { TknFixedButtonWrapper } from '../components/register/TknFixedButtonWrapper';
import { ConfirmCodeAction } from '../types/enums/ConfirmCodeAction';
import { localStorageService } from '../services/LocalStorageService';
import { useRouter } from 'next/router';
import { authApiService } from '../services/api/AuthApiService';
import { TknStepProgressBar } from '../components/TknStepProgressBar';
import { useError } from '../hooks/useError';
import { usePageThrobber } from '../hooks/usePageThrobber';
import { ErrorType } from '../types/enums/ErrorType';
import { TknValidationErrorSnackbar } from '../components/snackbars/TknValidationErrorSnackbar';
import { TknServerErrorSnackBar } from '../components/snackbars/TknServerErrorSnackBar';
import { AuthStep } from '../types/enums/AuthStep';
import {
  PHONE_NUMBER_PLACEHOLDER,
} from '../hooks/useClearEmptyInputError';
import { GetJwtPayload } from '../types/auth/GetJwtPayload';
import { TknEmailInput } from '../components/form/TknEmailInput';

interface LoginFormFields {
  phoneNumber?: string;
  emailAddress?: string;
  rememberMe: boolean;
}

export default function Login() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { error, setError } = useError();
  const {
    control,
    formState,
    getFieldState,
    getValues,
    handleSubmit,
    reset,
    // clearErrors,
  } = useForm<LoginFormFields>({
    mode: 'onChange',
    defaultValues: {
      phoneNumber: '', // (localStorageService.getPhoneNumber() as string) ?? "",
      emailAddress: '', //(localStorageService.getEmailAddress() as string) ?? "",
      rememberMe: false,
    },
  });
  const phoneNumberUsed =
    getFieldState('phoneNumber').isDirty &&
    getValues('phoneNumber') != PHONE_NUMBER_PLACEHOLDER;
  const emailAddressUsed =
    getFieldState('emailAddress').isDirty &&
    (getValues('emailAddress')?.length ?? 0) > 0;
    // console.log('hey', formState.errors);
  const router = useRouter();
  const { showThrobber, hideThrobber } = usePageThrobber();
  const formStateErrors = formState.errors;
  
  // useClearEmptyInputError({
  //   control,
  //   name: "phoneNumber", // todo: investigate this!!!
  //   clearErrors,
  //   placeholder: PHONE_NUMBER_PLACEHOLDER,
  // });

  const submitData = useCallback(
    (data: LoginFormFields) => {
      showThrobber();

      const { phoneNumber, emailAddress } = data;

      const international = (emailAddress?.length ?? 0) > 0;
      let authPayload: GetJwtPayload = {
        email: emailAddress,
        phone: phoneNumber,
        international,
      };

      authApiService
        .getAuthToken(authPayload)
        .then((response) => {
          localStorageService.setBearerToken(response.jwt.token);

          if (international) {
            localStorageService.setEmailAddress(emailAddress as string);
            localStorageService.setPhoneNumber('');
          } else {
            localStorageService.setEmailAddress('');
            localStorageService.setPhoneNumber(phoneNumber as string);
          }
          localStorageService.setInternational(international);

          router.push(
            `/confirm-code?action=${
              response.userExist
                ? ConfirmCodeAction.LOGIN
                : ConfirmCodeAction.REGISTER
            }`
          );
        })
        .catch((err) => {
          reset(undefined, {
            keepValues: true,
          });
          if (err.statusCode === 400) {
            return setError(ErrorType.VALIDATION_ERROR);
          }
          setError(ErrorType.SERVER_ERROR);
          hideThrobber();
        });
    },
    [router, reset, setError, showThrobber, hideThrobber]
  );

  const backLink = useMemo(() => {
    return localStorageService.getGuestModeEnabled() ? '/guest/home' : '/';
  }, []);

  useEffect(() => {
    localStorageService.setAuthFlowStep(AuthStep.LOGIN);
  }, []);

  return (
    <TknPageContainer>
      {error === ErrorType.VALIDATION_ERROR && (
        <TknValidationErrorSnackbar
          onClose={() => setError(null)}
          errorListOptions={[
            t('register.errorFields.phoneNumber', { ns: 'error' }),
          ]}
        />
      )}
      {error === ErrorType.SERVER_ERROR && (
        <TknServerErrorSnackBar onClose={() => setError(null)} />
      )}
      <Grid container direction="column" alignItems="start" gap={4}>
        <Grid item marginBottom={calculateiOSInset(64)}>
          <AppHeader
            leftChild={
              <Link href={backLink} passHref>
                <ArrowBackIcon />
              </Link>
            }
            rightChild={
              <TknStepProgressBar
                fillColor="global.yellow"
                barColor={alpha(theme.palette.global.white as string, 0.2)}
                currentStep={1}
                totalSteps={2}
              />
            }
          />
        </Grid>
        <Grid container direction="column" item gap={2}>
          <Grid item>
            <Typography variant="h3" align="left">
              {t('title', { ns: 'login' })}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="bodyRegular"
              align="left"
              dangerouslySetInnerHTML={{
                __html: t('description', { ns: 'login' }),
              }}
            />
          </Grid>
        </Grid>
        <Grid container item gap={3}>
          <Grid container item>
            <TknPhoneNumberInput
              id="phoneNumber"
              isDisabled={getFieldState('emailAddress').isDirty}
              label={(fieldState) =>
                fieldState.error?.type
                  ? t('invalidPhoneNumber', { ns: 'error' })
                  : t('phoneNumber', { ns: 'login' })
              }
              variant="standard"
              control={control}
              // rules={{ required: true }}
              rules={{}}
            />
          </Grid>
          {/* TODO: EMAIL SPLIT IS HERE */}
          <Grid container item>
            <TknEmailInput
              id="emailAddress"
              isDisabled={getFieldState('phoneNumber').isDirty}
              label={(fieldState) =>
                fieldState.error?.type
                  ? t('invalidEmailAddress', { ns: 'error' })
                  : t('emailAddress', { ns: 'login' })
              }
              variant="standard"
              control={control}
              rules={
                {
                  // required: emailAddressUsed
                }
              }
            />
          </Grid>
          <Grid container item justifyContent="space-between">
            <Grid item>
              <Typography variant="bodyRegular">
                {t('rememberMe', { ns: 'login' })}
              </Typography>
            </Grid>
            <Grid item>
              <TknSwitch
                id="rememberMe"
                control={control}
                // disabled={isFormDisabled}
                disabled={
                  formState.isSubmitting ||
                  (!phoneNumberUsed && !emailAddressUsed)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TknFixedButtonWrapper minimumHeight={500}>
        <TknButton
          id="login"
          variant="contained"
          styleVariant="yellow"
          size="large"
          // disabled={isFormDisabled}
          disabled={
            formState.isSubmitting ||
            (!phoneNumberUsed && !emailAddressUsed) 
            || Object.keys(formStateErrors).length > 0
          }
          label={t('logIn', { ns: 'splash' })}
          onClick={handleSubmit(submitData)}
        />
      </TknFixedButtonWrapper>
    </TknPageContainer>
  );
}

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      'common',
      'error',
      'login',
      'splash',
    ])),
  },
});
